import React from "react";
import {PagePoste} from 'amg-it-theme'; 

let pageData ={
  header: {
    background: "metiers",
    title: "commerce",
    path: "accueil / nos métiers / commerce / Ingénieur d’Affaires"
  },
  title: "Ingénieur d’Affaires", 
	missions:[
		{
		  text: "Mettre en œuvre les actions commerciales afin d’atteindre un objectif défini de chiffre d’affaires" 
		},
		{
		  text: "Constituer, gérer et développer un portefeuille de prospects et de clients sur un secteur d’activité défini" 
		},
		{
		  text: "Détecter les nouvelles opportunités commerciales (clients)" 
		},
		{
		  text: "Répondre aux appels d’offres" 
		},
	],
	key_skills: [
		{
		  text: "Communiquer avec charisme et conviction" 
		},
		{
		  text: "Mobiliser son énergie" 
		},
		{
		  text: "Etre orienté business" 
		},
		{
		  text: "Innover" 
		},
		{
		  text: "Autonomie" 
		},
		{
		  text: "Analyser et comprendre" 
		},
	],
	prerequisites:[
		{
		  text: "Maitrise du mode contractuel de l’infogérance" 
		},
		{
		  text: "Expression écrite et orale" 
		},
		{
		  text: "Maitrise des techniques commerciales (prospection, conclusion d’une vente, suivi client)" 
		},
		{
		  text: "Formation supérieure type école de commerce ou équivalent et expérience d’au moins deux ans sur un poste d’ingénieur commercial ou équivalent" 
		},
	],
	activities:[
		{
		  text: "Assurer une veille concurrentielle" 
		},
		{
		  text: "Identifier les clients potentiels à l’aide de l’outil de gestion commerciale et mettre en œuvre la prospection commerciale" 
		},
		{
		  text: "Détecter, cerner et analyser les besoins des prospects ou clients et identifier les circuits de décision" 
		},
		{
		  text: "Solliciter les autres services pertinents (Avant-vente, Opérations, Marketing…) et présenter une offre commerciale adaptée" 
		},
		{
		  text: "Négocier avec les clients, en accord avec sa hiérarchie, les prix, les délais, les quantités et les éléments du contrat" 
		},
		{
		  text: " " 
		},
		{
		  text: "Clôturer la vente" 
		},
		{
		  text: "Suivre et développer les relations commerciales avec les clients" 
		},
		{
		  text: "Assurer la mise à jour du reporting commercial" 
		},
		{
		  text: "Suivre le déroulement des prestations en termes de satisfaction client, de rentabilité financière et d’évolution" 
		}
	]

}

const LocalPage = () => (
  <PagePoste {...pageData}/>
);

export default LocalPage;